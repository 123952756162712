import {
  CellContentType,
  TableCategory,
  TableHeaders
} from '@/shared/components/table/models';
import moment from 'moment';
import { DATE_API_FORMAT } from '@/utils/date.util';
import { DropLiveType, LocationType } from '@/shared/models/woSchedule.model';
import { setPoolDisabled } from '@/pages/Dispatch/utils/set-chaiss-pool';
import {
  dropLiveOptions,
} from '@/pages/Dispatch/models/selectsOptions';

function getDay(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('MM/DD/YY')
    : schedule;
}

function getTime(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('hh:mm')
    : schedule;
}

export const Categories1TableHeaders: TableHeaders[] = [
  { key: 'number', label: 'NO', tdClass: 'min-w-20' },
  { key: 'orderNumber', label: 'WO #', tdClass: 'min-w-100' },
  { key: 'billTo', label: 'Bill To', tdClass: 'min-w-80' }
];

export const EquipmentTableHeaders: (pools) => TableHeaders[] = pools => [
  {
    key: 'containerNumber',
    label: 'CONT #',
    tdClass: 'min-w-80'
  },
  {
    key: 'shippingLine',
    rendererKey: 'equipment',
    label: 'SSL',
    tdClass: 'min-w-30'
  },
  {
    key: 'containerSize',
    rendererKey: 'equipment',
    label: 'SZ',
    collapsed: false,
    tdClass: 'min-w-30'
  },
  {
    key: 'chassisNumber',
    rendererKey: 'equipment',
    label: 'Chassis #',
    collapsed: false,
    content: {
      type: CellContentType.Text,
      validators: { name: 'regular', value: '^[a-zA-Z]{4}[0-9]{6}$' },
      validatorMessage:
        'This field is a required and must have 4 letters and 6 digits'
    },
    onChange: item => setPoolDisabled(item, pools),
    tdClass: 'min-w-130'
  },
  {
    key: 'chassisPool',
    rendererKey: 'equipment',
    label: 'POOL',
    collapsed: false,
    content: {
      type: CellContentType.List,
      list: pools,
      validators: { name: 'requiredIf', value: 'chassisNumber' },
      validatorMessage: 'This field is a required'
    },
    tdClass: 'min-w-130'
  },
  {
    key: 'bondEntry',
    rendererKey: 'equipment',
    label: 'Bond',
    collapsed: false,
    tdClass: 'min-w-80'
  }
];

export const InfoTableHeaders: TableHeaders[] = [
  {
    key: 'referenceNumber',
    label: 'REF',
    tdClass: 'min-w-80'
  },
  {
    key: 'poNumber',
    label: 'PO',
    rendererKey: 'info',
    collapsed: false,
    tdClass: 'min-w-80'
  },
  {
    key: 'overHeight',
    label: 'OH',
    rendererKey: 'info',
    collapsed: false,
    content: {
      type: CellContentType.Checkbox
    },
    tdClass: 'min-w-40'
  },
  {
    key: 'other',
    label: 'OTH',
    rendererKey: 'info',
    collapsed: false,
    content: {
      type: CellContentType.Checkbox
    },
    tdClass: 'min-w-40'
  }
];

export const BookingTableHeaders: TableHeaders[] = [
  {
    key: 'bookingNumber',
    label: 'Booking',
    tdClass: 'min-w-90'
  },
  {
    key: 'streetTurn',
    label: 'S/T',
    rendererKey: 'booking',
    collapsed: false,
    tdClass: 'min-w-90'
  },
  {
    key: 'erd',
    rendererKey: 'booking',
    label: 'ERD',
    collapsed: false,
    tdClass: 'min-w-90'
  },
  {
    key: 'cutOff',
    label: 'C/O',
    rendererKey: 'booking',
    collapsed: false,
    tdClass: 'min-w-90'
  }
];

export const LocationTableHeaders: TableHeaders[] = [
  {
    key: 'pulloutLocation',
    label: 'P/OUT',
    tdClass: 'min-w-80'
  },
  {
    key: 'deliveryLocation',
    label: 'DROP',
    rendererKey: 'loc',
    collapsed: false,
    tdClass: 'min-w-80'
  },
  {
    key: 'returnLocation',
    label: 'RTN',
    rendererKey: 'loc',
    collapsed: false,
    tdClass: 'min-w-80'
  }
];

export const GeoTableHeaders: TableHeaders[] = [
  { key: 'originLocation', label: 'Orig', tdClass: 'min-w-80' },
  {
    key: 'currLocation',
    label: 'Curr',
    rendererKey: 'geo',
    collapsed: false,
    tdClass: 'min-w-80'
  },
  { key: 'destLocation', label: 'Dest', rendererKey: 'geo', collapsed: false, tdClass: 'min-w-80' }
];

export const Categories2TableHeaders: TableHeaders[] = [
  {
    key: 'returnFreeDay',
    label: 'RFD',
    rendererKey: 'cat2',
    collapsed: false,
    tdClass: 'min-w-80'
  },
  {
    key: 'returnFreeDayLeft',
    label: 'RFD DAY',
    collapsed: false,
    tdClass: 'min-w-80'
  }
];

export const ScheduleTableHeaders: TableHeaders[] = [
  {
    key: 'pulloutSchedule',
    label: 'P/OUT Date',
    rendererKey: 'sch',
    tdClass: 'min-w-130'
  },
  {
    key: 'deliverySchedule',
    label: 'DILV Date',
    rendererKey: 'sch',
    tdClass: 'min-w-130'
  },
  {
    key: 'pulloutScheduleTime',
    label: 'TIME',
    rendererKey: 'sch',
    tdClass: 'min-w-130'
  },
  {
    key: 'dropLive',
    label: 'LIVE/DRP',
    rendererKey: 'sch',
    tdClass: 'min-w-130',
    content: {
      type: CellContentType.List,
      list: dropLiveOptions
    }
  },
  {
    key: 'loadConfirmation',
    label: 'Load CFM'
  },
  {
    key: 'pickupSchedule',
    label: 'P/U S Date',
    rendererKey: 'sch',
    tdClass: 'min-w-130',
    formatter: getDay,
    content: {
      type: CellContentType.Date,
      skipValidation: true,
      isDisabledBy: DropLiveType.LIVE
    }
  },
  {
    key: 'pickupScheduleTime',
    label: 'Time',
    rendererKey: 'sch',
    tdClass: 'min-w-130',
    formatter: getTime,
    content: {
      type: CellContentType.Time,
      skipValidation: true,
      isDisabledBy: DropLiveType.LIVE
    }
  },
  {
    key: 'pickupDriver',
    label: 'DRV ID',
    rendererKey: 'sch',
    tdClass: 'min-w-130',
    content: {
      type: CellContentType.Driver,
      locationType: LocationType.PICKUP,
      date: 'pickupSchedule',
      time: 'pickupScheduleTime',
      skipValidation: true,
      isDisabledBy: DropLiveType.LIVE
    }
  },
  {
    key: 'returnSchedule',
    label: 'RTN S Date',
    rendererKey: 'sch',
    tdClass: 'min-w-130',
    formatter: getDay,
    content: {
      type: CellContentType.Date,
      skipValidation: true
    }
  },
  {
    key: 'returnScheduleTime',
    label: 'Time',
    rendererKey: 'sch',
    tdClass: 'min-w-130',
    formatter: getTime,
    content: {
      type: CellContentType.Time,
      skipValidation: true
    }
  },
  {
    key: 'returnDriver',
    label: 'DRV ID',
    rendererKey: 'sch',
    tdClass: 'min-w-130',
    content: {
      type: CellContentType.Driver,
      locationType: LocationType.RETURN,
      date: 'returnSchedule',
      time: 'returnScheduleTime',
      skipValidation: true
    }
  }
];

export const Categories4TableHeaders: TableHeaders[] = [
  {
    key: 'save',
    label: 'Save',
    content: {
      type: CellContentType.Button,
      label: 'Save'
    },
    tdClass: 'min-w-60'
  },
  {
    key: 'complete',
    label: 'Complete',
    content: {
      type: CellContentType.Button,
      label: 'Complete'
    },
    tdClass: 'min-w-60'
  },
  {
    key: 'remarks',
    label: 'Remark',
    content: {
      type: CellContentType.Text,
      skipValidation: true
    }
  }
];

export const ReturnCategoriesHeaders: TableCategory[] = [
  {
    key: 'category1',
    label: ' ',
    maxColspan: Categories1TableHeaders.length,
    minColspan: 1
  },
  {
    key: 'equipment',
    label: 'EQUIPMENT',
    maxColspan: EquipmentTableHeaders([]).length,
    minColspan: 1,
    class: 'equipment-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'info',
    label: 'INFO',
    maxColspan: InfoTableHeaders.length,
    minColspan: 1,
    class: 'info-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'booking',
    label: 'BOOKING',
    maxColspan: BookingTableHeaders.length,
    minColspan: 1,
    class: 'booking-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'loc',
    label: 'LOCATION',
    maxColspan: LocationTableHeaders.length,
    minColspan: 1,
    class: 'location-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'geo',
    label: 'GEOFENCE',
    maxColspan: GeoTableHeaders.length,
    minColspan: 1,
    class: 'geofence-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'category2',
    label: '',
    maxColspan: Categories2TableHeaders.length,
    minColspan: 1
  },
  {
    key: 'sch',
    label: 'SCHEDULE',
    maxColspan: ScheduleTableHeaders.length,
    minColspan: 1,
    class: 'schedule-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'category4',
    label: '',
    maxColspan: Categories4TableHeaders.length,
    minColspan: 1
  }
];

export const ReturnTableHeaders: (pools) => TableHeaders[] = pools => [
  ...Categories1TableHeaders,
  ...EquipmentTableHeaders(pools),
  ...InfoTableHeaders,
  ...BookingTableHeaders,
  ...LocationTableHeaders,
  ...GeoTableHeaders,
  ...Categories2TableHeaders,
  ...ScheduleTableHeaders,
  ...Categories4TableHeaders
];
